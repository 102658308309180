import React, { useState } from "react";
import "./homeEducation.css";

import uCalgaryLogo from "../assets/home/img/school_logos/UofC.png";
import macEwanLogo from "../assets/home/img/school_logos/MacEwanU-Logo.png";

const HomeEducation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section className="section-education" id="education">
      <div className="education">
        <div className="collapsible-container">
          {/* <p className="education-header">Education</p> */}
          <button className="collapsible" onClick={toggleCollapsible}>
            Education
          </button>
        </div>

        {isOpen && (
          <div className="collapsible-content">
            <div className="container grid grid--2-cols">
              <div className="degree">
                <div className="education-img-box">
                  <img
                    className="school-logo-img"
                    src={uCalgaryLogo}
                    alt="University of Calgary Logo"
                  />
                </div>

                <div className="education-text-box">
                  <ul>
                    <h2 className="school-name">University of Calgary</h2>
                    <li>Bachelor of Science - B.Sc</li>
                    <li>Computer Science</li>
                    <li>2024</li>
                  </ul>
                </div>
              </div>

              <div className="degree">
                <div className="education-img-box">
                  <img
                    className="school-logo-img"
                    src={macEwanLogo}
                    alt="MacEwan University Logo"
                  />
                </div>

                <div className="education-text-box">
                  <ul>
                    <h2 className="school-name">MacEwan University</h2>
                    <li>Bachelor of Science - B.Sc</li>
                    <li>Molecular and Cellular Biology</li>
                    <li>Minor in Chemistry</li>
                    <li>2020</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default HomeEducation;
