import React from "react";
import { Helmet } from "react-helmet";

import "../components/general.css";
import "../components/style.css";
import "../components/queries.css";

import OmniHeader from "../components/omniHeader";
import OmniHero from "../components/omniHero";
import OmniFeature from "../components/omniFeature";
import OmniHow from "../components/omniHow";
import OmniMeals from "../components/omniMeals";
import OmniTestimonials from "../components/omniTestimonials";
import OmniPricing from "../components/omniPricing";
import OmniCTA from "../components/omniCTA";
import OmniFooter from "../components/omniFooter";

const Omnifood = () => {
  return (
    <div>
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />

        <meta
          name="description"
          content="Omnifood is an AI-powered food subscription that will make you eat healthy again, 365 days per year. It's tailored to your personal tastes and nutritional needs."
        />

        {/* <!-- Always include this line of code!!! --> */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <link rel="icon" href="../assets/omnifood/img/favicon.png" />
        <link
          rel="apple-touch-icon"
          href="../assets/omnifood/img/apple-touch-icon.png"
        />
        <link rel="manifest" href="../assets/omnifood/manifest.webmanifest" />

        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />

        <script
          type="module"
          src="https://unpkg.com/ionicons@5.4.0/dist/ionicons/ionicons.esm.js"
        ></script>
        <script
          nomodule=""
          src="https://unpkg.com/ionicons@5.4.0/dist/ionicons/ionicons.js"
        ></script>

        <script
          defer
          src="https://unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js"
        ></script>

        <title>Omnifood &mdash; Never Cook Again</title>
      </Helmet>
      <OmniHeader />
      <OmniHero />
      <OmniFeature />
      <OmniHow />
      <OmniMeals />
      <OmniTestimonials />
      <OmniPricing />
      <OmniCTA />
      <OmniFooter />
    </div>
  );
};

export default Omnifood;
