import React from "react";
import "./style.css";
import "./general.css";
import "./queries.css";

import logo1 from "../assets/omnifood/img/omnifood-logo.png";

import { FaInstagram, FaFacebook, FaTwitter } from "react-icons/fa";

const OmniFooter = () => {
  return (
    <footer className="footer">
      <div className="container grid grid--footer">
        <div className="logo-col">
          <a href="/omnifood" class="footer-logo">
            <img className="logo" alt="Omnifood logo" src={logo1} />
          </a>

          <ul className="social-links">
            <li>
              <a className="footer-link" href="/omnifood">
                <FaInstagram className="social-icon" />
              </a>
            </li>
            <li>
              <a className="footer-link" href="/omnifood">
                <FaFacebook className="social-icon" />
              </a>
            </li>
            <li>
              <a className="footer-link" href="/omnifood">
                <FaTwitter className="social-icon" />
              </a>
            </li>
          </ul>

          <p className="copyright">
            Copyright &copy; <span className="year">2027</span> by Omnifood,
            Inc. All rights reserved.
          </p>
        </div>

        <div className="address-col">
          <p className="footer-heading">Contact us</p>
          <address className="contacts">
            <p className="address">
              623 Harrison St., 2nd Floor, San Francisco, CA 94107
            </p>
            <p>
              <a className="footer-link" href="tel:415-201-6370">
                415-201-6370
              </a>
              <br />
              <a className="footer-link" href="mailto:hello@omnifood.com">
                hello@omnifood.com
              </a>
            </p>
          </address>
        </div>

        <nav className="nav-col">
          <p className="footer-heading">Account</p>
          <ul className="footer-nav">
            <li>
              <a className="footer-link" href="/omnifood">
                Create account
              </a>
            </li>
            <li>
              <a className="footer-link" href="/omnifood">
                Sign in
              </a>
            </li>
            <li>
              <a className="footer-link" href="/omnifood">
                iOS app
              </a>
            </li>
            <li>
              <a className="footer-link" href="/omnifood">
                Android app
              </a>
            </li>
          </ul>
        </nav>

        <nav className="nav-col">
          <p className="footer-heading">Company</p>
          <ul className="footer-nav">
            <li>
              <a className="footer-link" href="/omnifood">
                About Omnifood
              </a>
            </li>
            <li>
              <a className="footer-link" href="/omnifood">
                For Business
              </a>
            </li>
            <li>
              <a className="footer-link" href="/omnifood">
                Cooking partners
              </a>
            </li>
            <li>
              <a className="footer-link" href="/omnifood">
                Careers
              </a>
            </li>
          </ul>
        </nav>

        <nav className="nav-col">
          <p className="footer-heading">Resources</p>
          <ul className="footer-nav">
            <li>
              <a className="footer-link" href="/omnifood">
                Recipe directory{" "}
              </a>
            </li>
            <li>
              <a className="footer-link" href="/omnifood">
                Help center
              </a>
            </li>
            <li>
              <a className="footer-link" href="/omnifood">
                Privacy & terms
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default OmniFooter;
