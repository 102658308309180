import React, { useState } from "react";
import "./homeProjects.css";

import omnifoodLogo from "../assets/home/img/projects/omnifood-logo.png";
import csfLogo from "../assets/home/img/projects/csf.jpg";
import sunlinesLogo from "../assets/home/img/projects/sunlines_logo.png";

const HomeProjects = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section className="section-projects" id="projects">
      <div className="projects">
        <div className="collapsible-container">
          <button className="collapsible" onClick={toggleCollapsible}>
            Projects
          </button>
        </div>

        {isOpen && (
          <div className="collapsible-content">
            <div className="container center-text margin-bottom-md">
              <span className="home-tertiary">Past and Present Projects!</span>
            </div>

            <div className="container grid grid--3-cols">
              <div className="project-item">
                <img
                  className="project-img project-img--omni"
                  src={omnifoodLogo}
                  alt="Omnifood Project"
                />

                <div className="project-content">
                  <div className="project-tag">
                    <span className="tag tag--completed">Completed</span>
                  </div>

                  <p className="project-title">
                    Udemy - Course Project: Omnifood
                  </p>

                  <p className="project-description">
                    Informational Website to advertise meal plans to consumers.
                  </p>

                  <a href="/omnifood" className="btn btn--full">
                    Check it out!
                  </a>
                </div>
              </div>

              <div className="project-item">
                <img
                  className="project-img"
                  src={csfLogo}
                  alt="Clean Strength Fitness Project"
                />

                <div className="project-content">
                  <div className="project-tag">
                    <span className="tag tag--inprogress">
                      Web Design Phase
                    </span>
                  </div>

                  <p className="project-title">
                    Client Site - Clean Strength Fitness
                  </p>

                  <p className="project-description">
                    Informational Website design to introduce potential clients
                    to online strength coaches. In additon to a e-commerce
                    store.
                  </p>

                  {/* <a href="#" class="btn">Check it out!</a>  */}
                </div>
              </div>

              <div className="project-item">
                <img
                  className="project-img"
                  src={sunlinesLogo}
                  alt="Sunlines Project"
                />

                <div className="project-content">
                  <div className="project-tag">
                    <span className="tag tag--inprogress">
                      Web Design Phase
                    </span>
                  </div>

                  <p className="project-title">
                    Client Site - Sunlines Alterations & Convenience
                  </p>

                  <p className="project-description">
                    Informational Website on the products and services provided
                    by the business
                  </p>

                  {/* <a href="#" class="btn">Check it out!</a> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default HomeProjects;
