import React from "react";
import "../components/general.css";

import HomeHeader from "../components/homeHeader";
import HomeHero from "../components/homeHero";
import HomeEducation from "../components/homeEducation";
import HomeProjects from "../components/homeProjects";

const Home = () => {
  return (
    <div>
      <HomeHeader />
      <HomeHero />
      <HomeEducation />
      <HomeProjects />
    </div>
  );
};

export default Home;
