import React from "react";
import "./homeHero.css";

import heroImg from "../assets/home/img/profile_photo/profile.png";

const HomeHero = () => {
  return (
    <section className="section-home-hero">
      <div className="home-hero">
        <div class="home-hero-img-box">
          <img className="home-hero-img" src={heroImg} alt={"Alan Huynh"} />
        </div>

        <div class="home-hero-text-box">
          <h1 class="home-heading-primary">Hi There!!</h1>
          <p class="home-hero-description">
            My name is Alan! I inspire to one day be a full stack developer! I
            am a fourth year comp sci student at the University of Calgary. In
            my personal time I have completed a HTML/CSS course and I am
            currently working on a JavaScript course on the side. This semester
            at UofC I am taking a database course revolving around SQL
            programming language. This semester I have used JavaScript with
            React libraries and PostgresSQL with Django frameworks to develop my
            projects. After this semester I hope to have an establish understand
            in front end developer and some back end development understand
            through SQL.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
